import * as api from './api.js'
import { ProvenanceTrustBadge } from './trust-badge/index.js'
import { ProvenanceExperience } from './experience.js'
import { ProvenanceBundle, setProductID } from './bundle.js'
import modalCSS from './modal.min.css'
import { findBundleContentsId } from './structured-data.js'

/**
 * A function that does all necessary checks and populates Provenance HTML tags with Provenance content.
 * Experiences are rendered first, then Trust Badges and Bundles.
 * @returns {boolean} true if all content was successfully rendered; otherwise it will raise an error.
 */
export async function mountAll () {
  document.querySelectorAll('provenance-experience').forEach(initializeExperience)

  // Initial state
  const trustBadge = document.querySelector('provenance-trust-badge')
  const bundle = document.querySelector('provenance-bundle')
  if (!bundle) { return false }

  // Handle trust badges based on attribute presence
  if (trustBadge) {
    if (trustBadge.hasAttribute('shopify-shop-id')) {
      await handleTrustBadgeWithShopifyId(trustBadge, bundle)
      await handleBundle(bundle)
    } else if (trustBadge.hasAttribute('access-token')) {
      const identifier = await handleBundle(bundle)
      if (!identifier) { return false }
      await handleTrustBadgeWithAccessToken(trustBadge, identifier, bundle)
    }
  } else if (bundle) {
    // Only log trust badge not found error on product bundles
    document.querySelectorAll('provenance-bundle').forEach(initializeBundle)
    logError('Trust Badge not found.')
  } else {
    return false
  }
  return true
}

export async function handleTrustBadgeWithShopifyId (trustBadge, bundle) {
  const apiOrigin = trustBadge.getAttribute('api-origin')
  const shopifyShopId = trustBadge.getAttribute('shopify-shop-id')
  const identifier = {
    identifier: trustBadge.getAttribute('product-identifier'),
    type: 'sku'
  }

  const offersResponse = await api.provenanceContent({ apiOrigin, shopifyShopId, identifier })
  if (offersResponse?.ok) {
    const autoRenderBundle = await ProvenanceTrustBadge(trustBadge, offersResponse)
    initializeBundle(bundle, autoRenderBundle)
    return true
  } else {
    const errorData = await offersResponse.json()
    logError(`Error: ${errorData.error.code} - ${errorData.error.message}`)
    return false
  }
}

export async function handleTrustBadgeWithAccessToken (trustBadge, identifier, bundle) {
  const accessToken = trustBadge.getAttribute('access-token')
  const apiOrigin = trustBadge.getAttribute('api-origin')

  const offersResponse = await api.provenanceContent({ accessToken, apiOrigin, identifier })
  if (offersResponse?.ok) {
    const autoRenderBundle = await ProvenanceTrustBadge(trustBadge, offersResponse)
    initializeBundle(bundle, autoRenderBundle)
    return true
  } else {
    const errorData = await offersResponse.json()
    logError(`Error: ${errorData.error.code} - ${errorData.error.message}`)
    return false
  }
}

export async function handleBundle (bundle) {
  let identifier = null
  const bundleUrl = bundle.getAttribute('url')
  if (bundle && bundleUrl) {
    if (bundleTypeInUrl(bundleUrl)) {
      return true
    } else {
      identifier = await findBundleContentsId()
      return identifier
    }
  }
  return false
}

function bundleTypeInUrl (bundleUrl) {
  bundleUrl.includes('/user') || bundleUrl.includes('/example') || bundleUrl.includes('/product')
}

function initializeExperience (el) {
  const accessToken = el.getAttribute('access-token')
  const apiOrigin = el.getAttribute('api-origin')
  const batch = el.getAttribute('batch')
  const countryCode = el.getAttribute('country-code')
  const gtin = el.getAttribute('gtin')
  const origin = el.getAttribute('origin')
  const passportId = el.getAttribute('passport-id')
  const schema = el.getAttribute('schema')
  const url = el.getAttribute('url')

  ProvenanceExperience({
    accessToken,
    apiOrigin,
    batch,
    container: el,
    countryCode,
    gtin,
    origin,
    passportId,
    schema,
    url
  })
}

function initializeBundle (el, autoRenderBundle) {
  const schema = el.getAttribute('schema')
  const url = el.getAttribute('url')

  ProvenanceBundle({
    container: el,
    schema,
    url,
    autoRenderBundle
  })
}

function logError (msg) { console.warn('Provenance:', msg) }

// CSS from app needs to be injected in the parent page for modals to display
document.head.insertAdjacentHTML('beforeend', `<style>${modalCSS}</style>`)

/**
 * This is a temporary hack to ensure that the Provenance content is rendered after the page has loaded.
 * It's specific to cultbeauty.co.uk's mobile site.
 * In the future we might consider adding a way of making this trigger in a configurable way, if we find
 * that it's useful for other clients.
 */
if (location.href.includes('cultbeauty') && window.innerWidth < 900) {
  setTimeout(() => {
    mountAll()
  }, 5000)
}

mountAll()

export { ProvenanceExperience, ProvenanceBundle, setProductID }
